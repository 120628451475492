import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIndex } from "../stateMachine";
import AnimatedImage from "../../../ui/AnimatedImage";

const INTERVAL = 5000;
const API_URL = process.env.REACT_APP_API_URL;

const ScreenCarousel = () => {
  const index = useSelector(({ stateMachine }) => stateMachine.index);
  const clientName = useSelector(({ stateMachine }) => stateMachine.clientName);
  const img1 = useSelector(({ stateMachine }) => stateMachine.img1);
  const dispatch = useDispatch();

  const eventImage = new URL(`/api/${clientName}/image.png`, API_URL);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setIndex(index + 1));
    }, INTERVAL);
  }, [index]);

  return (
    <>
      <AnimatedImage src={eventImage} duration={INTERVAL * 0.3} />
      <AnimatedImage src={img1} duration={INTERVAL * 0.3} alignRight />
    </>
  );
};

const Home = () => {
  return (
    <div className="ScreenCarousel__content">
      <ScreenCarousel />
    </div>
  );
};

export default Home;
