import { useEffect, useState } from "react";
import Camera from "./Camera";
import ImagePreview from "./ImagePreview";

import "./Camera.scss";

const CameraComponent = ({
  onSuccess = (event) => {},
  onCancel = (event) => {},
  automaticPersona,
}) => {
  const [preview, setPreview] = useState(null);

  const renderPreview = () => {
    const onAccept = () => {
      onSuccess({ target: { value: preview } });
    };

    const onReject = () => {
      setPreview(false);
    };

    return (
      <ImagePreview
        preview={preview}
        onAccept={onAccept}
        onReject={onReject}
        automaticPersona={automaticPersona}
      />
    );
  };

  const renderCamera = () => {
    const handleCancel = () => {
      window._CAMERA_MANAGER.removeSource();
      window._CAMERA_MANAGER = null;
      onCancel();
    };

    const handleCapture = (data) => {
      setPreview(data);

      window._CAMERA_MANAGER.removeSource();
      window._CAMERA_MANAGER = null;
    };

    return (
      <Camera
        onCapture={handleCapture}
        onCancel={handleCancel}
        automaticPersona={automaticPersona}
      />
    );
  };

  if (preview) {
    return renderPreview();
  }

  return renderCamera();
};

export default CameraComponent;
