import { useSelector } from "react-redux";


import ContactForm from "./pages/ContactForm";
import Success from "./pages/Success";

const STEP_COMPONENTS = {
  contactForm: <ContactForm />,
  success: <Success />,
};

const StateRouter = () => {
  let currentStep = useSelector(({ stateMachine }) => stateMachine.step);

  return STEP_COMPONENTS[currentStep]
};

export default StateRouter;
