
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AnimatedImage from "../AnimatedImage";

const Carousel = ({
  transitionInterval = 5000,
  transitionDurationFactor = 0.3,
  setIndex
}) => {
  const index = useSelector(({ stateMachine }) => stateMachine.index);
  const img1 = useSelector(({ stateMachine }) => stateMachine.img1);
  const img2 = useSelector(({ stateMachine }) => stateMachine.img2);

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(() => {
      dispatch(setIndex(index + 2));
    }, transitionInterval);
  }, [index]);

  return (
    <>
      <AnimatedImage
        src={img1}
        duration={transitionInterval * transitionDurationFactor}
      />

      <AnimatedImage
        src={img2}
        duration={transitionInterval * transitionDurationFactor}
      />
    </>
  );
};

export default Carousel;
