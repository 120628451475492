import { useState, useRef, useEffect } from "react";
import CameraManager from "./CameraManager";
import Spinner from "../Spinner";

import closeIcon from "../../../assets/close.png";
import flipIcon from "../../../assets/flip.png";

const Camera = ({ onCapture, onCancel, automaticPersona }) => {
  const [cameraReady, setCameraReady] = useState(false);
  const stream = useRef(null);

  const handleCapture = () => {
    window._CAMERA_MANAGER.capture(automaticPersona ? 1 : 1.5);
  };

  const handleFlip = () => {
    window._CAMERA_MANAGER.toggleSource();
  };

  const onCameraReady = (e) => {
    const { readyState } = e.target;
    setCameraReady(readyState >= 2);
  };

  useEffect(() => {
    if (stream.current) {
      stream.current.addEventListener("loadeddata", onCameraReady);
    }
  }, [onCameraReady]);

  useEffect(() => {
    if (!window._CAMERA_MANAGER) {
      window._CAMERA_MANAGER = new CameraManager(stream.current, {
        onCapture,
      });
    }
  }, []);

  return (
    <div
      className={`Camera ${
        automaticPersona ? "Automatic" : ""
      } Camera--capture ${cameraReady ? "Camera--ready" : ""}`}
    >
      <div className="Camera__container">
        <div className="Camera__img">
          <video
            autoPlay={true}
            muted={true}
            playsInline={true}
            ref={stream}
            id="preview"
            src="#"
            className={`Camera__video ${cameraReady ? "is-ready" : ""}`}
          />

          <div className="Camera__spinner">
            <Spinner />
          </div>
        </div>
        <div className="Camera-actions">
          <button className="Camera-actions-button__small" onClick={onCancel}>
            <img src={closeIcon} />
          </button>

          <div className="Camera-actions__middle">
            <button
              className="Camera-actions-button__capture"
              onClick={handleCapture}
              aria-label="Capture"
            ></button>
          </div>
          <button className="Camera-actions-button__small" onClick={handleFlip}>
            <img src={flipIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Camera;
