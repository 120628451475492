import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { setGroupCurrent, resetGroup, completeGroup } from "./stateMachine";
import I18n from "../../ui/I18n";

import "./GroupDrawer.scss";

const GroupDrawer = () => {
  const step = useSelector(({ stateMachine }) => stateMachine.step);
  const hash = useSelector(({ stateMachine }) => stateMachine.hash);
  const group = useSelector(({ stateMachine }) => stateMachine.group) || [];
  const groupCurrent =
    useSelector(({ stateMachine }) => stateMachine.groupCurrent) || 0;
  const groupMax =
    useSelector(({ stateMachine }) => stateMachine.groupMax) || 0;
  const dispatch = useDispatch();

  const selectGroup = (groupIndex) => {
    dispatch(setGroupCurrent(groupIndex));
  };

  const handleReset = (groupIndex) => {
    dispatch(resetGroup());
  };

  const handleComplete = () => {
    dispatch(completeGroup({ hash, group }));
  };

  const renderImage = ({ id, image }, index) => {
    const renderStatus = () => {
      if (id) {
        if (image) {
          return <span className="GroupDrawer__item--ready">&#10004; </span>;
        } else {
          return (
            <span className="GroupDrawer__item--processing">&infin; </span>
          );
        }
      }
    };

    return (
      <div
        key={`drawer_photo_${index}`}
        className={classNames("GroupDrawer__item", {
          "GroupDrawer__item--selected": index === groupCurrent,
          "GroupDrawer__item--empty": image === null,
        })}
        onClick={selectGroup.bind(this, index)}
      >
        {renderStatus()}
        <I18n t="groupDrawer.photo" /> {index + 1}
      </div>
    );
  };

  const startedGroups = group.filter(({ id }) => id);
  const hasPhotos = startedGroups.length > 0;
  const unlocked =
    startedGroups.length > 1 &&
    startedGroups.reduce((curr, { id, image }) => {
      if (id) {
        curr = curr && id && image;
      }

      return curr;
    }, true);

  if (["personaPhoto", "qrscanner"].includes(step)) {
    return null;
  }

  if (groupMax > 1) {
    return (
      <div className="GroupDrawer">
        <div className="GroupDrawer__list">{group.map(renderImage)}</div>

        {hasPhotos ? (
          <div className="GroupDrawer__buttons">
            <button
              className="Button Button--secondary Button--small"
              onClick={handleReset}
            >
              <I18n t="groupDrawer.reset" />
            </button>
            <button
              className="Button Button--primary Button--small"
              disabled={!unlocked}
              onClick={handleComplete}
            >
              <I18n t="groupDrawer.complete" />
            </button>
          </div>
        ) : null}
      </div>
    );
  }
};

export default GroupDrawer;
