import { useState, useEffect } from "react";
import { setIndex } from "../stateMachine";
import AnimatedImage from "../../../ui/AnimatedImage";
import { useSelector, useDispatch } from "react-redux";
import Carousel from "../../../ui/Carousel";

import "./Home.scss";

const INTERVAL = 5000;
const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
const API_URL = process.env.REACT_APP_API_URL;

const ScreenCarousel = () => {
  const index = useSelector(({ stateMachine }) => stateMachine.index);
  const clientName = useSelector(({ stateMachine }) => stateMachine.clientName);
  const img1 = useSelector(({ stateMachine }) => stateMachine.img1);
  const dispatch = useDispatch();

  const eventImage = new URL(`/api/${clientName}/image.png`, API_URL);

  useEffect(() => {
    setTimeout(() => {
      dispatch(setIndex(index + 1));
    }, INTERVAL);
  }, [index]);

  return (
    <>
      <AnimatedImage src={eventImage} duration={INTERVAL * 0.3} />
      <AnimatedImage src={img1} duration={INTERVAL * 0.3} alignRight />
    </>
  );
};

const VideoAdd = () => {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    if (!window._VIDEO_TIMEOUT) {
      const time = display ? 45 * 1000 : 3 * 60 * 1000;
      console.log("video", { time });
      window._VIDEO_TIMEOUT = setTimeout(() => {
        setDisplay(!display);
        window._VIDEO_TIMEOUT = false;
      }, time);
    }
  });

  if (!display) {
    return null;
  }

  return (
    <video
      className="ScreenCarousel__cta"
      autoPlay={true}
      muted={true}
      playsInline={true}
      src={new URL("video.mp4", PUBLIC_URL)}
    />
  );
};

const Home = () => {
  const clientName = useSelector(({ stateMachine }) => stateMachine.clientName);

  return (
    <div className="ScreenCarousel__content">
      <Carousel setIndex={setIndex} />
      <VideoAdd />
    </div>
  );
};

export default Home;
