import { useEffect, useRef } from "react";
import classNames from "classnames";

const AnimatedImage = ({
  className = "",
  src,
  duration,
  alignRight = false,
}) => {
  const currentSrc = useRef(null);
  const nextSrc = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    container.current.style.setProperty("--duration", duration / 1000);

    nextSrc.current.src = src;
    nextSrc.current.classList.add("transition");
    currentSrc.current.classList.add("transition");

    setTimeout(() => {
      nextSrc.current.classList.add("active");
      currentSrc.current.classList.remove("active");

      setTimeout(() => {
        currentSrc.current.src = src;
        nextSrc.current.classList.remove("transition");
        currentSrc.current.classList.remove("transition");
      }, duration * 1.2);

      setTimeout(() => {
        nextSrc.current.classList.remove("active");
        currentSrc.current.classList.add("active");
      }, duration * 1.3);
    }, 100);
  }, [src]);

  return (
    <div
      ref={container}
      className={classNames("AnimatedImage", className, {
        "AnimatedImage--right": alignRight,
      })}
      style={{ "--duration": duration }}
    >
      <img ref={currentSrc} className="AnimatedImage-item" />
      <img ref={nextSrc} className="AnimatedImage-item active" />
    </div>
  );
};

export default AnimatedImage;
