import { useState, useEffect, useRef } from "react";
import closeIcon from "../../../../assets/close.png";
import flipIcon from "../../../../assets/flip.png";

import { useDispatch, useSelector } from "react-redux";
import QrScanner from "qr-scanner";
import { setQRUid, setState } from "../stateMachine";

import Spinner from "../../../ui/Spinner";
import I18n from "../../../ui/I18n";

import "./QRScanner.scss";

const QRScanner = () => {
  const camera = useRef(null);
  const qrScanner = useRef();
  const dispatch = useDispatch();
  const [status, setStatus] = useState("");
  const readCounter = useRef(0);
  const read = useRef(null);
  const styles = useSelector(({ stateMachine }) => stateMachine.styles);

  const handleConfirmedScan = (result) => {
    if (qrScanner.current) qrScanner.current.stop();
    dispatch(setQRUid(result));
    dispatch(setState("personaForm"));
  };

  const handleScan = (result) => {
    if (result.data === read.current) {
      if (readCounter.current < 6) {
        setStatus("reading");

        if (readCounter.current === 5) {
          setStatus("success");
          handleConfirmedScan(result.data);
        }
        readCounter.current++;
      }
    } else {
      readCounter.current = 0;
      read.current = result.data;
    }
  };

  useEffect(() => {
    if (camera.current && !qrScanner.current) {
      QrScanner.hasCamera();
      qrScanner.current = new QrScanner(camera.current, handleScan, {
        highlightScanRegion: true,
        highlightCodeOutline: true,
        maxScansPerSecond: 10,
        preferredCamera:
          localStorage.getItem("camera-qrScanner") || "environment",
      });

      window.TMLC = QrScanner;
      window.TMLC2 = qrScanner.current;
      qrScanner.current.start();
      setStatus("started");
    }
  });
  const renderHelper = () => {
    if (status === "") {
      return (
        <div className="QRScanner__start-instructions" onClick={handleStart}>
          <div>Tap to start</div>
        </div>
      );
    }
  };

  const handleStart = () => {
    qrScanner.current.start();
    setStatus("started");
  };

  const handleCancel = () => {
    qrScanner.current?.destroy();
    qrScanner.current = null;
    setStatus("");
  };
  const handleFlip = async () => {
    if (!localStorage.getItem("cameras")) {
      localStorage.setItem(
        "cameras",
        JSON.stringify(await QrScanner.listCameras())
      );
    }
    const cameras = JSON.parse(localStorage.getItem("cameras"));
    const currentCameraIndex = cameras.findIndex(
      ({ id }) => id == localStorage.getItem("camera-qrScanner")
    );

    if (currentCameraIndex + 1 < cameras.length) {
      localStorage.setItem(
        "camera-qrScanner",
        cameras[currentCameraIndex + 1].id
      );
    } else {
      localStorage.setItem("camera-qrScanner", cameras[0].id);
    }

    qrScanner.current.setCamera(localStorage.getItem("camera-qrScanner"));
  };

  return (
    <div className="QRScanner">
      <div className="QRScanner__container">
        <div className="QRScanner__frame">
          <div className="QRScanner__elements">
            <div className={`QRScanner__scanner Camera__img ${status}`}>
              <video
                ref={camera}
                autoPlay={true}
                muted={true}
                playsInline={true}
                id="preview"
                src="#"
                className="Camera__img-bg"
              />
              {renderHelper()}
            </div>

            {status === "started" || status === "reading" ? (
              <div className="QRScanner__loading">
                <Spinner className="QRScanner__spinner" />
              </div>
            ) : null}
          </div>
        </div>

        <div className="QRScanner__actions">
          <button className="Camera-actions-button__small" onClick={handleCancel}>
            <img src={closeIcon} />
          </button>

          <div className="QRScanner__middle Camera-actions__middle">
            <I18n t="qrScanner.instructions" />
          </div>

          <button className="Camera-actions-button__small" onClick={handleFlip}>
            <img src={flipIcon} />
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRScanner;
