import classNames from "classnames";
import { useSelector } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";

import Crossfade from "./Crossfade";

import "swiper/css";
import "swiper/css/pagination";

const StylesCarousel = (props) => {
  const lang = useSelector((state) => state.stateMachine.lang);
  const translations = useSelector(
    ({ stateMachine }) => stateMachine.translations
  );
  const { style = null } = useSelector(
    ({ stateMachine }) => stateMachine.photo_settings
  );
  const {
    className,
    options = {},
    onChange = null
  } = props || {};

  const onSlideChange = (swiper) => {
    if (onChange === null) return;

    const { realIndex } = swiper || {};
    const active = Object.keys(options)[realIndex];

    onChange(active);
  };

  const renderSlide = (id) => {
    if (!id) return null;

    const images = options[id];
    const name = translations?.[lang]?.styles?.[id] || id;

    return (
      <SwiperSlide key={id} className="StylesCarousel__slide">
        {({ isActive }) => (
          <div className="StylesCarousel__slide-container">
            {name ? (
              <div className="StylesCarousel__style-name">{name}</div>
            ) : null}

            <Crossfade
              images={images}
              duration={500}
              delay={1500}
              active={isActive}
            />
          </div>
        )}
      </SwiperSlide>
    );
  };

  return (
    <div className={classNames({
      "StylesCarousel": true,
      [className]: className,
    })}>
      {options ? (
        <Swiper
          initialSlide={
            style !== null ? Object.keys(options).indexOf(style) : 0
          }
          slidesPerView={1.25}
          loop={Object.keys(options).length >= 4 ? true : false}
          centeredSlides={true}
          pagination={false}
          className="StylesCarousel__swiper"
          onSlideChange={onSlideChange}
        >
          {Object.keys(options).map(renderSlide)}
        </Swiper>
      ) : null}
    </div>
  );
};

export default StylesCarousel;
