import { useState } from "react";
import CameraComponent from "../../../ui/Camera";
import { useDispatch, useSelector } from "react-redux";
import { setPhoto, setReset, setState } from "../stateMachine";
import { submitEntry } from "../../../../configs/api";

const PersonaPhoto = () => {
  const clientName = useSelector(({ stateMachine }) => stateMachine.clientName);
  const photo_settings = useSelector(
    ({ stateMachine }) => stateMachine.photo_settings
  );
  const automaticPersona = useSelector(
    ({ stateMachine }) => stateMachine.automaticPersona
  );
  const [lock, setLock] = useState(false);

  const dispatch = useDispatch();

  const handleSuccess = async (e) => {
    if (lock) {
      return;
    }
    setLock(true);

    const photo = e.target.value;

    window._currentPhoto = photo;

    const user = await submitEntry({
      client_name: clientName,
      photo_settings: {
        ...photo_settings,
        automatic: automaticPersona ? "true" : "false",
      },
      photo,
    });

    dispatch(setPhoto(user));
    dispatch(setState("contactForm"));
  };

  const handleCancel = async (e) => {
    dispatch(setReset());
  };

  return (
    <CameraComponent
      onSuccess={handleSuccess}
      onCancel={handleCancel}
      automaticPersona={automaticPersona}
    />
  );
};

export default PersonaPhoto;
