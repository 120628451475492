import { useSelector } from "react-redux";

import Home from "./pages/Home";

const STEP_COMPONENTS = {
  home: <Home />,
};

const StateRouter = () => {
  let currentStep = useSelector((state) => state.stateMachine.step);

  return STEP_COMPONENTS[currentStep]
};

export default StateRouter;
