import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setContact, setReset } from "../stateMachine";

import I18n from "../../../ui/I18n";
import Phone from "../../../ui/Phone";
import Select from "../../../ui/Select";
import Spinner from "../../../ui/Spinner";

import "./ContactForm.scss";

const ContactForm = () => {
  const generating = useSelector(({ stateMachine }) => stateMachine.generating);
  const customFields = useSelector(
    ({ stateMachine }) => stateMachine.customFields || []
  );
  const lang = useSelector(({ stateMachine }) => stateMachine.lang);
  const [data, setData] = useState({
    lang,
    first_name: "",
    last_name: "",
    phone: "",
    ...customFields.reduce((cur, field) => {
      cur[field.name] = field.default;
      return cur;
    }, {}),
  });
  const dispatch = useDispatch();

  const handleInput = (field, e) => {
    let value = e.target.value;

    setData({
      ...data,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(setContact(data));
  };

  const handleReset = async (e) => {
    dispatch(setReset());
    setData({});
  };

  const renderCustomFields = () => {
    return customFields.map((field) => {
      return (
        <div className="Form__field">
          <label>{field.label}</label>
          <Select
            name={field.name}
            required={field.name}
            options={field.options}
            onChange={handleInput.bind(this, field.name)}
            value={data[field.name]}
          />
          {field.hint && (
            <label
              className="Form__hint"
              dangerouslySetInnerHTML={{
                __html: field.hint,
              }}
            ></label>
          )}
        </div>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit} className="ContactForm Form Form--row">
      {/* TODO: i18n message is empty */}
      {/* {generating && (
        <div className="Header-status-bar">
          <I18n t="form.generation_status" />
        </div>
      )} */}

      <div className="ContactForm__field Form__field">
        <label htmlFor="first_name">
          <I18n t="form.first_name.label" />
        </label>
        <input
          id="first_name"
          type="text"
          name="first_name"
          required={true}
          onInput={handleInput.bind(this, "first_name")}
          value={data?.first_name}
          autoComplete="off"
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label htmlFor="last_name">
          <I18n t="form.last_name.label" />
        </label>
        <input
          id="last_name"
          type="text"
          name="last_name"
          required={true}
          onInput={handleInput.bind(this, "last_name")}
          value={data?.last_name}
          autoComplete="off"
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label>
          <I18n htmlFor="phone" t="form.phone.label" />
        </label>
        <Phone
          id="phone"
          name="phone"
          required={data?.email ? false : true}
          onInput={handleInput.bind(this, "phone")}
          value={data?.phone}
          autoComplete="off"
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label>
          <I18n t="form.language.label" />
        </label>
        <Select
          name="language"
          required={true}
          tKey={`form.language.options`}
          onChange={handleInput.bind(this, "lang")}
          value={data?.lang}
        />
      </div>

      {renderCustomFields()}

      <div className="ContactForm__buttons">
        <button
          type="submit"
          className={`ContactForm__button Button Button--primary ${generating ? 'ContactForm__button-loading'  : ''}`}
          disabled={generating}
        >
          {generating ? (
            <>
              <Spinner className="ContactForm__button-spinner" />
              <I18n t="form.generating" />
            </>
          ) : (
            <I18n t="form.submit" />
          )}
        </button>
        <button
          type="button"
          className="ContactForm__button Button Button--secondary"
          onClick={handleReset}
        >
          <I18n t="form.reset" />
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
