import { useEffect, useState } from "react";
import I18n from "../../../ui/I18n";
import { useDispatch, useSelector } from "react-redux";
import { setState, setReset } from "../stateMachine";
import Phone from "../../../ui/Phone";
import Select from "../../../ui/Select";
import Spinner from "../../../ui/Spinner";

import { submitEntry } from "../../../../configs/api";

import "./ContactForm.scss";

const ContactForm = () => {
  const clientName = useSelector(({ stateMachine }) => stateMachine.clientName);
  const customFields = useSelector(
    ({ stateMachine }) => stateMachine.customFields || []
  );
  const lang = useSelector(({ stateMachine }) => stateMachine.lang);
  const [data, setData] = useState({
    lang,
    ...customFields.reduce((cur, field) => {
      cur[field.name] = field.default;
      return cur;
    }, {}),
  });

  const [sending, setSending] = useState(false);

  const dispatch = useDispatch();

  const handleInput = (field, e) => {
    let value = e.target.value;

    if (e.target.type === "file") {
      value = e.target.files[0];
    }

    setData({
      ...data,
      [field]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setSending(true);

    const { photo, ...form } = data;

    await submitEntry({
      client_name: clientName,
      ai: "false",
      photo_settings: {},
      photo,
      form: JSON.stringify(form),
    });
    dispatch(setState("success"));
  };

  const handleReset = async (e) => {
    window.location.reload()
  };

  const renderCustomFields = () => {
    return customFields.map((field) => {
      return (
        <div className="Form__field">
          <label>{field.label}</label>

          <Select
            name={field.name}
            required={field.name}
            options={field.options}
            onChange={handleInput.bind(this, field.name)}
            value={data[field.name]}
          />

          {field.hint && (
            <label
              className="hint"
              dangerouslySetInnerHTML={{
                __html: field.hint,
              }}
            ></label>
          )}
        </div>
      );
    });
  };

  return (
    <form onSubmit={handleSubmit} className="ContactForm Form Form--row">
      <div className="ContactForm__field Form__field">
        <label>
          <I18n t="form.photo.label" />
        </label>

        <input
          type="file"
          accept="image/png, image/jpeg"
          name="photo"
          onInput={handleInput.bind(this, "photo")}
          required={true}
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label htmlFor="first_name">
          <I18n t="form.first_name.label" />
        </label>

        <input
          id="first_name"
          type="text"
          name="first_name"
          required={true}
          onInput={handleInput.bind(this, "first_name")}
          value={data?.first_name}
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label htmlFor="last_name">
          <I18n t="form.last_name.label" />
        </label>

        <input
          id="last_name"
          type="text"
          name="last_name"
          required={true}
          onInput={handleInput.bind(this, "last_name")}
          value={data?.last_name}
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label>
          <I18n htmlFor="phone" t="form.phone.label" />
        </label>

        <Phone
          id="phone"
          name="phone"
          required={data?.email ? false : true}
          onInput={handleInput.bind(this, "phone")}
          value={data?.phone}
        />
      </div>

      <div className="ContactForm__field Form__field">
        <label>
          <I18n t="form.language.label" />
        </label>

        <Select
          name="language"
          required={true}
          tKey={`form.language.options`}
          onChange={handleInput.bind(this, "lang")}
          value={data?.lang}
        />
      </div>

      {renderCustomFields()}

      <div className="ContactForm__buttons">
        <button
          type="submit"
          className={`ContactForm__button Button Button--primary`}
          disabled={sending}
          >
            {sending ? (
              <Spinner className="ContactForm__button-spinner" />
            ) : ( 
              <I18n t="form.submit" />
            )
            }
        </button>

        <button
          type="button"
          className="ContactForm__button Button Button--secondary"
          disabled={sending}
          onClick={handleReset}
        >
          <I18n t="form.reset" />
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
