const API_URL = process.env.REACT_APP_API_URL
const submitEntry = async ({
  id = null,
  photo_settings,
  photo,
  client_name,
  ai,
  form
}) => {
  const body = new FormData();

  body.append("photo_settings", JSON.stringify(photo_settings));
  body.append("photo", photo);
  body.append("client_name", client_name);
  if(ai){
    body.append("ai", ai);
  }
  if(form){
    body.append("form", form);
  }
  if (id) {
    body.append("id", id);
  }
  console.log(body)
  return await fetch(new URL("/api/new", API_URL), {
    method: "POST",
    body,
  }).then((res) => res.json());
};


export {
  submitEntry
}
