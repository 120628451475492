import { useState } from "react";

const DEFAULT_FORMAT = "({0}{1}{2}) {3}{4}{5}-{6}{7}{8}{9}";

const Phone = ({ name, value, placeholder, onInput, autoComplete, required }) => {
  const [deleteMode, setDeleteMode] = useState(false);

  const handleInput = (e) => {
    return onInput(e);
    let value = DEFAULT_FORMAT;
    if (deleteMode) {
      value = e.target.value;
    } else {
      e.target.value
        .replaceAll(/[^\d]/g, "")
        .split("")
        .forEach((char, index) => {
          value = value.replace(`{${index}}`, char);
        });

      if (value.includes("{")) {
        value = value.split("{")[0];
      }
    }

    onInput({
      target: {
        value,
      },
    });
  };

  const onKeyDown = (e) => {
    setDeleteMode(e.key === "Backspace");
  };

  return (
    <input
      type="tel"
      name={name}
      required={required}
      maxLength="24"
      onKeyDown={onKeyDown.bind(this)}
      onInput={handleInput.bind(this)}
      value={value}
      placeholder={placeholder}
      autoComplete={autoComplete}
    />
  );
};

export default Phone;
