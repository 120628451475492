import { useState } from "react";
import I18n from "../../../ui/I18n";
import { useDispatch, useSelector } from "react-redux";
import { setConfirmPhotoForm, setReset } from "../stateMachine";
import { translate } from "../../../ui/I18n";

import "./PersonaForm.scss";

const PersonaForm = () => {
  const lang = useSelector(({ stateMachine }) => stateMachine.lang);
  const translations = useSelector(
    ({ stateMachine }) => stateMachine.translations
  );
  const variableLabels = translate(lang, "variableLabels");
  const variables = useSelector(({ stateMachine }) => stateMachine.variables);
  const requiredVariables = useSelector(({ stateMachine }) => [
    "style",
    ...stateMachine.workflowSettings.requiredVariables,
  ]);
  const photo_settings = useSelector(
    ({ stateMachine }) => stateMachine.photo_settings
  );
  const automaticPersona = useSelector(
    ({ stateMachine }) => stateMachine.automaticPersona
  );
  const [data, setData] = useState(photo_settings || {});
  const dispatch = useDispatch();

  const handleInput = (field, e) => {
    let value = e.target.value;

    setData({
      ...data,
      [field]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (e.target.checkValidity()) {
      dispatch(setConfirmPhotoForm(data));
    }
  };

  const handleReset = async (e) => {
    dispatch(setReset());
    setData({});
  };

  const renderVariableField = ([variable, options]) => {
    return (
      <div key={variable} className={`Form__field ${variable}`}>
        <label htmlFor={variable}>
          <I18n t={`fieldLabels.${variable}`} />
        </label>

        <select
          id={variable}
          name={variable}
          value={data[variable] || "—"}
          required={requiredVariables.includes(variable)}
          onChange={handleInput.bind(this, variable)}
        >
          <option
            key={"empty"}
            value={""}
            disabled={false}
            hidden={true}
          ></option>

          {options.map((option) => {
            const option_label =
              variableLabels?.[variable]?.[option] ||
              translations?.[lang]?.styles?.[option] ||
              option;

            return (
              <option key={`${variable}-${option}`} value={option}>
                {option_label}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  const renderStyleButtons = (options) => {
    return options.map((option) => {
      const option_label = translations?.[lang]?.styles?.[option] || option;

      return (
        <label
          key={`s_${option}`}
          htmlFor={`style_${option}`}
          className="Button Button__radio "
        >
          <input
            type="radio"
            name="style"
            required={true}
            id={`style_${option}`}
            onChange={handleInput.bind(this, "style")}
            value={option}
            checked={option === data?.style}
          />
          {option_label}
        </label>
      );
    });
  };

  const renderVariables = () => {
    if (Object.entries(variables).length === 0) {
      return "No variables available";
    }

    if (automaticPersona) {
      return (
        <>
          <div className="PersonaForm__heading">
            <I18n t="form.header.style_selection" />
          </div>

          <div className="PersonaForm__styles Form__buttons">
            {renderStyleButtons(variables["style"])}
          </div>
        </>
      );
    }

    return (
      <div className="PersonaForm__fields">
        {Object.entries(variables).map(renderVariableField)}
      </div>
    );
  };

  return (
    <div className="PersonaForm Form">
      <form onSubmit={handleSubmit}>
        {renderVariables()}

        <div>
          <div className="PersonaForm__buttons">
            <button type="submit" className="Button Button--primary">
              <I18n t="form.submit" />
            </button>

            {!automaticPersona && (
              <button
                type="button"
                className="Button Button--secondary"
                onClick={handleReset}
              >
                <I18n t="form.reset" />
              </button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default PersonaForm;
