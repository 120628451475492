
import Router from "./Router";
import Main from "../workflows/Main";
import ScreenResult from "../workflows/ScreenResult";
import ScreenCarousel from "../workflows/ScreenCarousel";
import ScreenCarouselTrio from "../workflows/ScreenCarouselTrio";
import ScreenCarouselCta from "../workflows/ScreenCarouselCta";
import ScreenLast from "../workflows/ScreenLast";
import Simple from "../workflows/Simple";

const routes = [{
  path: '/',
  component: Main,
  exact: true
}, {
  path: '/screen-result',
  component: ScreenResult,
  exact: true
}, {
  path: '/screen-carousel',
  component: ScreenCarousel,
  exact: true
}, {
  path: '/screen-carousel-cta',
  component: ScreenCarouselCta,
  exact: true
}, {
  path: '/screen-last',
  component: ScreenLast,
  exact: true
}, {
  path: '/screen-trio',
  component: ScreenCarouselTrio,
  exact: true
},{
  path: '/:clientName',
  component: Main,
  exact: true
}, {
  path: '/:clientName/screen-result',
  component: ScreenResult,
  exact: true
},  {
  path: '/:clientName/simple',
  component: Simple,
  exact: true
}, {
  path: '/:clientName/screen-carousel',
  component: ScreenCarousel,
  exact: true
}, {
  path: '/:clientName/screen-trio',
  component: ScreenCarouselTrio,
  exact: true
}, {
  path: '/:clientName/screen-carousel-cta',
  component: ScreenCarouselCta,
  exact: true
}, {
  path: '/:clientName/screen-last',
  component: ScreenLast,
  exact: true
}]

function Layout() {
  return (
    <Router routes={routes}/>
  );
}

export default Layout;
