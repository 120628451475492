import { Provider, PageRedux, ConnectionIndicator } from "rtcf/client";

import StateRouter from "./StateRouter";
import Header from "./Header";
import GroupDrawer from "./GroupDrawer";
import SDAPIStatus from "./SDAPIStatus";

import stateMachine, { initialState } from "./stateMachine";
import { useState } from "react";

import Spinner from "../../ui/Spinner";

window.updateUnlocked = false;
const SDAPI =
  new URLSearchParams(document.location.search).get("sdapi") === "true";
const SKIP_CONTACT =
  new URLSearchParams(document.location.search).get("skipContact") === "true";

const Main = ({
  match: {
    params: { hash, clientName },
  },
}) => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState(null);

  if (!clientName) {
    clientName = "default";
  }

  const fetchVariables = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${clientName}/variables`
      );
      if (response.ok) {
        const results = await response.json();

        const groupMax = results?.appSettings?.groupMax || 1;
        const qr = results?.appSettings?.qr || false;
        const lang = results?.appSettings?.lang || "fr-CA";
        const theme = results?.appSettings?.theme || "Wallrus";
        const customFields = results?.appSettings?.customFields || [];
        const variables = results?.sdVariables?.variables || {};
        const styles = results?.styles || [];
        const translations = results?.appSettings?.translations || {};
        const group = [...Array(groupMax)].map(() => ({
          id: null,
          image: null,
        }));


        let step = "selectAutomatic";
        
        if(!results?.appSettings.automaticPersona){
          step = "styleSelection";
        }

        const workflowSettings = {
          qr,
          automatic: results?.appSettings.automaticPersona,
          skipContact: SKIP_CONTACT || results?.appSettings?.skipContact || false,
          requiredVariables: results?.appSettings?.requiredVariables || [],
        };

        setSettings({
          step,
          lang,
          variables,
          group,
          qr,
          styles,
          translations,
          workflowSettings,
          groupMax,
          theme,
          customFields,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  if (!settings) {
    fetchVariables();
  }

  if (loading) {
    return (
      <div
        style={{
          position: "fixed",
          top: "50%",
          left: "50%",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          transform: "translate(-50%, -50%)",
          minWidth: "320px",
        }}
      >
        <div
          style={{
            width: "2rem",
            height: "2rem",
            color: "white",
            marginBottom: "1rem",
          }}
        >
          <Spinner />
        </div>
        <div style={{ fontSize: "0.75rem" }}>Loading settings...</div>
      </div>
    );
  }

  const state = {
    ...initialState,
    clientName,
    ...settings,
  };

  document.querySelector("body").classList.add(settings.theme);

  return (
    <Provider
      namespace="/main"
      state={state}
      hash={"form"}
      additionalParams={{ clientName }}
      autoConnect={true}
      stateMachine={stateMachine}
    >
      <GroupDrawer />
      <PageRedux className={`Main ${settings.theme}`}>
        <Header />
        <StateRouter />

        <div className="Main__connection-indicator">
          <ConnectionIndicator hidePositive={true} />
        </div>
        {SDAPI && <SDAPIStatus />}
      </PageRedux>
    </Provider>
  );
};

export default Main;
