// import { useState, useEffect } from "react";
// import I18n from "rtcf-client/src/components/ui/I18n";
// import { useEffect, useState } from "react";
// import { useSelector } from "react-redux";


const Home = () => {
  return (
    <div className="HomePage__content">

    </div>
  );
};

export default Home;
