import React from "react";

import "./Spinner.scss";

const SPIRES = 12;
const WIDTH = 5;
const LENGTH = 20;
const STAGGER = 100;

const s = [];

for (let i = 0; i <= SPIRES; i++) {
  s.push({
    angle: (360 / SPIRES) * i,
  });
}

const Spinner = (props) => {
  const { className, style } = props || {};

  return (
    <div
      className={`Spinner ${className ? className : ''}`}
      style={style}
    >
      {s.map((spire, i) => {
        const { angle } = spire || {};

        return (
          <div
            key={angle}
            className="Spinner__spire-container"
            style={{
              width: `${WIDTH}%`,
              height: `100%`,
              transform: `translateX(-50%) rotate(${angle}deg)`,
              animationDuration: `${SPIRES * STAGGER}ms`,
              animationDelay: `${i * STAGGER}ms`,
            }}
          >
            <div
              className="Spinner__spire"
              style={{
                height: `${LENGTH}%`,
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default Spinner;
