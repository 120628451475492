class CameraManager {
  #videoDOM;
  #events;
  #affinity = localStorage.getItem("camera-selected");
  #mediaStream;
  #active = false;
  
  constructor(videoDOM, events) {
    this.#videoDOM = videoDOM;
    this.#events = events;
    this.applySource();

  }

  getSettings() {
    return {
      video: {
        width: { ideal: 2100 },
        height: { ideal: 2100 },
        deviceId: { exact: this.#affinity },
      },
    };
  }

  removeSource() {
    this.#mediaStream?.getTracks().forEach(track => track.stop());
    this.#videoDOM.pause();
    this.#videoDOM.srcObject = null;

    this.#mediaStream = null;
  }

  b64toBlob(b64Data, contentType='', sliceSize=512){
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  async capture(zoom = 1.5) {
    const streamSettings = this.#mediaStream.getTracks()[0].getSettings();
    const canvas = document.createElement("canvas");
    const portrait = window.screen.orientation.type.includes("portrait");
    const baseWidth = !portrait ? streamSettings.width : streamSettings.height;
    const baseHeight = !portrait ? streamSettings.height : streamSettings.width;

    canvas.width = baseWidth///1.5;
    canvas.height = baseHeight;

    const Zwidth = baseWidth * zoom;
    const Zheight = baseHeight * zoom;
    
    const Zx = (canvas.width - Zwidth) / 2;
    const Zy = (baseHeight - Zheight) / 2;

    canvas
      .getContext("2d")
      .drawImage(this.#videoDOM, Zx, Zy, Zwidth, Zheight);
      // .drawImage(this.#videoDOM, 0, 0, canvas.width, canvas.height);
    const imagebase64data = canvas.toDataURL("image/jpeg").split(',')[1];

    this.#events.onCapture && this.#events.onCapture(this.b64toBlob(imagebase64data, "image/jpeg"));
  }

  async applySource() {
    if(!this.#affinity){
      const cameras = await this.loadVideoInputs();

      this.#affinity = cameras[0].id;
      localStorage.setItem("camera-selected", this.#affinity);
    }

    navigator.mediaDevices
      .getUserMedia(this.getSettings())
      .then((mediaStream) => {
        console.log(`[CameraManager] switch ${this.#affinity}`, mediaStream)
        this.#videoDOM.srcObject = mediaStream;
        this.#mediaStream = mediaStream;
      }).catch(console.error);
  }

  async loadVideoInputs() {
    const devices = await navigator.mediaDevices.enumerateDevices();
    const cameras = devices.filter(({kind}) => kind === 'videoinput').map(({deviceId, label})=>({id: deviceId, label}));

    localStorage.setItem("cameras", JSON.stringify(cameras));

    return cameras;
  }

  async toggleSource() {
    if(!localStorage.getItem("cameras")){
      this.loadVideoInputs();
    }

    const cameras = JSON.parse(localStorage.getItem("cameras"));
    const currentCameraIndex = cameras.findIndex(({id})=> id == this.#affinity);

    if (currentCameraIndex + 1  < cameras.length) {
      this.#affinity = cameras[currentCameraIndex + 1].id;
    }else{
      this.#affinity = cameras[0].id;
    }

    localStorage.setItem("camera-selected", this.#affinity);
    this.removeSource()
    this.applySource();
  }
}

export default CameraManager;
