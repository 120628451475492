import { useSelector } from "react-redux";

const SDAPIStatus = () => {
  const SDAPIStatus = useSelector(({ stateMachine }) => stateMachine.SDAPIStatus)
  return (
    <div className={`SDAPI SDAPI--${SDAPIStatus?.status}`}>
      SDAPI
      <div>
        <div className="SDAPI-status">
          <strong>Status:</strong> {SDAPIStatus?.status}
        </div>
        <div className="SDAPI-message">
          <strong>Error:</strong> {SDAPIStatus?.status === 'online' ? '' : SDAPIStatus?.message}
        </div>
      </div>
    </div>
  )
}

export default SDAPIStatus;
