import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPhotoForm, setState } from "../stateMachine";

import StylesCarousel from "../../../ui/StylesCarousel";
import I18n from "../../../ui/I18n";

import "./StyleSelection.scss";

const StyleSelection = () => {
  const dispatch = useDispatch();
  const styles = useSelector(({ stateMachine }) => stateMachine.styles);

  const handleStart = () => {
    dispatch(setState("qrscanner"));
  };

  const handleStyleChange = (style) => {
    dispatch(setPhotoForm({ style }));
  };
  useEffect(() => {
    dispatch(setPhotoForm({ style: Object.keys(styles)[0] }));
  }, []);

  return (
    <div className="StyleSelection">
      <div className="StyleSelection__carousel">
        <StylesCarousel options={styles} onChange={handleStyleChange} />
      </div>

      <div className="StyleSelection__actions">
        <button
          type="button"
          className="Button Button--primary StyleSelection__button"
          onClick={handleStart}
        >
          <I18n t="qrScanner.startBtnLabel" />
        </button>
      </div>
    </div>
  );
};

export default StyleSelection;
