import {useEffect} from  'react';
import { useSelector } from "react-redux";

import Home from "./pages/Home";

import PersonaForm from './pages/PersonaForm';
import PersonaPhoto from './pages/PersonaPhoto';
import ContactForm from "./pages/ContactForm";
import PhotoSelection from "./pages/PhotoSelection";
import Success from "./pages/Success";
import QRScanner from "./pages/QRScanner";
import SelectAutomatic from "./pages/SelectAutomatic";
import StyleSelection from "./pages/StyleSelection";

const STEP_COMPONENTS = {
  home: <Home />,
  selectAutomatic: <SelectAutomatic />,
  styleSelection: <StyleSelection />,
  qrscanner: <QRScanner />,
  personaForm: <PersonaForm />,
  personaPhoto: <PersonaPhoto />,
  contactForm: <ContactForm />,
  photoSelection: <PhotoSelection />,
  success: <Success />,
};

const StateRouter = () => {
  let currentStep = useSelector(({ stateMachine }) => stateMachine.step);


  return STEP_COMPONENTS[currentStep]
};

export default StateRouter;
