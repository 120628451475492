import { useSelector } from "react-redux";
import { translate } from "./I18n";

const Option = ({
  value,
  label,
  disabled = false,
  hidden = false,
}) => {
  return (
    <option
      key={value}
      {...{
        value,
        disabled,
        hidden,
      }}
    >
      {label}
    </option>
  );
};

const Select = ({ name, value, options, tKey, onChange, required }) => {
  const lang = useSelector(({ stateMachine }) => stateMachine.lang);
  if (!options) {
    options = translate(lang, tKey);
  }

  return (
    <select name={name} defaultValue={value} onChange={onChange} required={required}>
      {options.map((option) => (
        <Option key={option.value} {...option} />
      ))}
    </select>
  );
};


export default Select
