import React, { useState, useEffect } from "react";
import { AnimatePresence, motion } from "framer-motion";

import "./Crossfade.scss";

const Crossfade = (props) => {
  const {
    images,
    style,
    imageStyle,
    duration = 1000,
    delay = 2000,
    active = false,
  } = props || {};
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let interval;

    if (active) {
      interval = setInterval(() => {
        setIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, duration + delay);
    }

    return () => interval ? clearInterval(interval) : null;
  }, [active, images.length, duration, delay]);

  return (
    <div
      className="Crossfade"
      style={style}
    >
      <AnimatePresence mode="">
        <motion.img
          className="Crossfade__image"
          key={index}
          src={images[index]}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: duration / 1000 }}
          style={imageStyle}
          draggable={false}
        />
      </AnimatePresence>
    </div>
  );
};

export default Crossfade;
