import { useDispatch } from "react-redux";
import { setAutomaticPersona } from "../stateMachine";
import I18n from "../../../ui/I18n";

const SelectAutomatic = () => {
  const dispatch = useDispatch();

  const handleEnableAutomatic = () => {
    dispatch(setAutomaticPersona(true))
  }

  const handleDisableAutomatic = () => {
    dispatch(setAutomaticPersona(false))
  }

  return (
    <div className="SelectAutomaticPage__content">

      <div className="Header-status-bar">
        <I18n t='selectAutomatic.title' />
      </div>

      <div className="Form__field-photo actions">
        <button onClick={handleEnableAutomatic} className="Button Button--primary">
          <I18n t='selectAutomatic.buttons.enable' />
        </button>
        <button onClick={handleDisableAutomatic} className="Button Button--primary">
          <I18n t='selectAutomatic.buttons.disable' />
        </button>
      </div>
    </div>
  );
};

export default SelectAutomatic;
