import I18n from "../I18n";

const ImagePreview = ({ preview, onAccept, onReject, automaticPersona }) => {
  return (
    <div className={`Camera ${automaticPersona ? "Automatic" : ""}`}>
      <div className="Camera__container">
        <div className="Camera__img">
          <div className="Camera__flash" />
          <img
            id="preview"
            src={URL.createObjectURL(preview)}
            className="ImagePreview__img-bg"
          />
        </div>

        <div className="Camera-actions Camera-actions--wrap">
          <div className="Camera-actions__question">
            <I18n t="form.photoPreview.question" />
          </div>

          <button className="Button Button--secondary" onClick={onReject}>
            <I18n t="form.photoPreview.reject" />
          </button>

          <button className="Button Button--primary " onClick={onAccept}>
            <I18n t="form.photoPreview.accept" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ImagePreview;
