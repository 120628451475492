import { useEffect, useState } from "react";
import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { submitEntry } from "../../../../configs/api";
import { setSelectPhoto, reroll } from "../stateMachine";
import Spinner from "../../../ui/Spinner";
import I18n from "../../../ui/I18n";

import "swiper/css";
import "swiper/css/pagination";

import "./PhotoSelection.scss";

const USE_CAROUSEL_PHOTO_SELECTOR = true; // TODO dev flag; might move to appSettings?
// const GEN_IMAGES = [
//   "https://toolbox.wallrus.tech/api/v1/sd/proxy/2/bell-paralympics/dev-0000000000000083_generated__00001_.jpg",
//   "https://toolbox.wallrus.tech/api/v1/sd/proxy/2/bell-paralympics/dev-0000000000000083_generated__00002_.jpg",
//   "https://toolbox.wallrus.tech/api/v1/sd/proxy/2/bell-paralympics/dev-0000000000000083_generated__00003_.jpg",
// ];

const PhotoSelection = () => {
  const generatedImages = useSelector(
    ({ stateMachine }) => stateMachine.generatedImages
  );
  const photo_settings = useSelector(
    ({ stateMachine }) => stateMachine.photo_settings
  );
  const id = useSelector(({ stateMachine }) => stateMachine.id);
  const generating = useSelector(({ stateMachine }) => stateMachine.generating);

  const [photo, setPhoto] = useState(null);
  const dispatch = useDispatch();

  const onSlideChange = (swiper) => {
    const { realIndex } = swiper || {};
    const active = generatedImages[realIndex];

    setPhoto(active);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!photo) return;

    dispatch(setSelectPhoto(photo));
  };

  const handleReroll = async () => {
    await submitEntry({
      id,
      photo_settings: photo_settings,
      photo: window._currentPhoto,
    });

    dispatch(reroll());
  };

  useEffect(() => {
    if (generatedImages && generatedImages.length > 0) {
      setPhoto(generatedImages[0]);
    }
  }, [generatedImages]);

  const renderSlide = (photoOption, id) => {
    return (
      <SwiperSlide key={photoOption} className="PhotoSelection__slide">
        <div className="PhotoSelection__slide-container">
          <img
            src={photoOption}
            className="Form__field-photo-img PhotoSelection__image"
          />
        </div>
      </SwiperSlide>
    );
  };

  const renderActionButtons = () => {
    return (
      <>
        <button
          type="submit"
          className="Button Button--primary"
          disabled={generating || !photo}
        >
          <I18n t="form.submit" />
        </button>

        <button
          type="button"
          className="Button Button--secondary"
          disabled={generating}
          onClick={handleReroll}
        >
          <I18n t="form.reroll" />
        </button>
      </>
    );
  };

  return (
    <form onSubmit={handleSubmit} className="PhotoSelection">
      <div className="Header-status-bar">
        {!generating && generatedImages.length === 1 ? (
          <I18n t="form.header.photo_selection_unique" />
        ) : (
          <I18n t="form.header.photo_selection" />
        )}
        {generating && (
          <>
            <br />
            <I18n t="form.generation_status" />
          </>
        )}
      </div>

      <AnimatePresence mode="wait">
        {!generating ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            className="PhotoSelection__carousel"
          >
            <div className="PhotoSelection__photos">
              <Swiper
                slidesPerView={1.25}
                centeredSlides={true}
                pagination={false}
                className="PhotoSelection__swiper"
                onSlideChange={onSlideChange}
              >
                {generatedImages.map(renderSlide)}
              </Swiper>
            </div>
          </motion.div>
        ) : (
          <div className="PhotoSelection__loading">
            <Spinner className="PhotoSelection__spinner" />
          </div>
        )}
      </AnimatePresence>

      <AnimatePresence>
        {!generating ? (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, delay: 1 }}
            className="PhotoSelection__actions"
          >
            {renderActionButtons()}
          </motion.div>
        ) : null}
      </AnimatePresence>
    </form>
  );
};

export default PhotoSelection;
