import {
  Provider,
  PageRedux,
  ConnectionIndicator,
} from "rtcf/client";

import StateRouter from "./StateRouter";

import stateMachine, { initialState } from "./stateMachine";

window.updateUnlocked = false;

const ScreenCarousel = ({
  match: {
    params: { hash, clientName },
  },
}) => {
  if (!clientName) { clientName = 'default' }
  const state = {
    ...initialState,
    clientName
  };

  return (
    <Provider
      namespace="/main"
      state={state}
      hash={'carousel'}
      autoConnect={true}
      additionalParams={{ clientName }}
      stateMachine={stateMachine}
    >
      <PageRedux className="Main BG-WHITE">
        <StateRouter />

        <div className="Main__connection-indicator">
          <ConnectionIndicator hidePositive={true} />
        </div>
      </PageRedux>
    </Provider>
  );
};

export default ScreenCarousel;
