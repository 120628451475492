// import { useState, useEffect } from "react";
// import I18n from "rtcf-client/src/components/ui/I18n";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIndex } from "../stateMachine";

const INTERVAL = 5000;

const AnimatedImage = ({ src, duration }) => {
  const currentSrc = useRef(null);
  const nextSrc = useRef(null);
  const container = useRef(null);

  useEffect(() => {
    container.current.style.setProperty('--duration', duration / 1000);

    nextSrc.current.src = src;
    nextSrc.current.classList.add('transition')
    currentSrc.current.classList.add('transition')
    setTimeout(() => {

      nextSrc.current.classList.add('active')
      currentSrc.current.classList.remove('active')

      setTimeout(() => {
        currentSrc.current.src = src;
        nextSrc.current.classList.remove('transition')
        currentSrc.current.classList.remove('transition')

      }, duration * 1.2);

      setTimeout(() => {
        nextSrc.current.classList.remove('active')
        currentSrc.current.classList.add('active')
      }, duration * 1.3);
    }, 100)
  }, [src])

  return (
    <div ref={container} className="AnimatedImage" style={{ '--duration': duration }}>
      <img ref={currentSrc} className="AnimatedImage-item" />
      <img ref={nextSrc} className="AnimatedImage-item active" />
    </div>
  )
}

const ScreenCarousel = () => {
  const index = useSelector(({ stateMachine }) => stateMachine.index);
  const img1 = useSelector(({ stateMachine }) => stateMachine.img1);
  const img2 = useSelector(({ stateMachine }) => stateMachine.img2);
  const dispatch = useDispatch();


  useEffect(() => {
    setTimeout(() => {
      dispatch(setIndex(index + 2));
    }, INTERVAL);

    // return () => {
    //   clearTimeout(window._CAROUSEL);
    // }
  }, [index]);


  return (
    <>
      <AnimatedImage src={img1} duration={INTERVAL * 0.3} />

      <AnimatedImage src={img2} duration={INTERVAL * 0.3} />
    </>
  );

}

const Home = () => {
  return (
    <div className="ScreenCarousel__content">
      <ScreenCarousel />
    </div>
  );
};

export default Home;
