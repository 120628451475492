import { createSlice } from "@reduxjs/toolkit";

const getValidImage = (images, index)=>{
  return images[index % images.length];
}

export const initialState = {
  clientName: "default",
  step: "home",
  lang: "en-CA",
  images: [],
  index: 0,
  img1: '',

};

export const stateMachine = createSlice({
  name: "stateMachine",
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(
        type,
        JSON.stringify({
          type: "stateMachine/onMessage",
          payload: { type, param },
        })
      );

      switch (type) {
        case "images":
          state.images = param;
          break;
      }
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      window._WSConnection["carousel-main"].io.connect();
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    setState: (state, { payload }) => {
      state.step = payload;
    },
    setIndex: (state, { payload: index }) => {
      state.index = index;
      state.img1 = getValidImage(state.images, index)
      console.log(state.index,
        state.img1)
    }
  },
});

// Action creators are generated for each case reducer function
export const { onMessage, onDisconnection, onConnection, setState, setIndex } =
  stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine;
