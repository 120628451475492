import { useDispatch } from "react-redux";
import { setState } from "../stateMachine";
import I18n from "../../../ui/I18n";

import thumbsUP from '../../../../assets/thumbs-up.png';

const Successs = () => {
  const dispatch = useDispatch();
  
  const handleReset = () => {
    dispatch(setState("contactForm"))
  }

  return (
    <div className="SuccesssPage__content">
      <img src={thumbsUP} />
      <I18n t='success.success' />

      <button onClick={handleReset} className="Button Button--primary Button--big">
        <I18n t='success.buttons.restart' />
      </button>
    </div>
  );
};

export default Successs;
