import { createSlice } from "@reduxjs/toolkit";

const WORKFLOW_SETTINGS = {
  qr: false,
  automatic: true,
  skipContact: false,
  requiredVariables: []
};

export const initialState = {
  clientName: "default",
  step: "selectAutomatic",
  
  workflowSettings: {
    ...WORKFLOW_SETTINGS,
  },
  // automatic
  automaticPersona: null,
  hasAutomaticPersona: false,

  customFields: [],
  finalImage: null,
  variables: {},
  theme: "Wallrus",
  lang: "fr-CA",
  first_name: "",
  last_name: "",
  id: null,
  generating: false,
  generatedImages: [],
  photo: "",
  photo_settings: {},
  form: {},

  // group settings
  groupMax: 1,
  groupCurrent: 0,
  group: [
    {
      id: null,
      image: null,
    },
  ],
  // SD API STATUS
  SDAPIStatus: {
    status: "",
    message: "",
  },

  qr: false,
  qrUid: null,
};

export const stateMachine = createSlice({
  name: "stateMachine",
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(
        type,
        JSON.stringify({
          type: "stateMachine/onMessage",
          payload: { type, param },
        })
      );

      switch (type) {
        case "SDAPIStatus":
          state.SDAPIStatus = param;
          break;

        case "generatedImages":
          const { id, generatedImages } = param;

          if (state.id === id) {
            state.generatedImages = generatedImages;
            state.generating = false;
          }
          break;

        case "subimission":

          if(state.id === param.id) {
            state.finalImage = param.photo.split("//").join("/")
          }

          const groupIndex = state.group.findIndex(({ id }) => id === param.id);

          state.group[groupIndex] = {
            ...state.group[groupIndex],
            image: param.photo,
          };
          break;
        default:
      }
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      window._WSConnection["form-main"].io.connect();
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    setState: (state, { payload }) => {
      if (payload === "qrscanner" && !state.workflowSettings.qr) {
        payload = "personaForm";
      }

      if (payload === "personaForm" && state.workflowSettings.automatic) {
        payload = "personaPhoto";
      }

      if (payload === "contactForm" && state.workflowSettings.skipContact) {
        payload = "photoSelection";
      }

      state.step = payload;
    },
    setPhotoForm: (state, { payload }) => {
      state.photo_settings = payload;
    },
    setConfirmPhotoForm: (state, { payload }) => {
      state.photo_settings = payload;

      state.step = "personaPhoto";
    },
    setPhoto: (state, { payload }) => {
      state.id = payload.id;
      state.generating = true;
      // group enabled
      state.group[state.groupCurrent] = {
        id: payload.id,
      };
      window._RDX["form-main"].ws.sendMessage("updateExternalID", {
        id: payload.id,
        externalUID: state.qrUid,
      });
    },
    setContact: (state, { payload }) => {
      state.first_name = payload.first_name;
      state.last_name = payload.last_name;
      state.form = {
        ...payload,
      };

      window._RDX["form-main"].ws.sendMessage("updateContact", {
        id: state.id,
        first_name: payload.first_name,
        last_name: payload.last_name,
        form: {
          ...payload,
        },
      });

      state.step = "photoSelection";
    },
    setSelectPhoto: (state, { payload }) => {
      window._RDX["form-main"].ws.sendMessage("updatePhoto", {
        id: state.id,
        photo: payload,
      });

      state.step = "success";
    },
    setReset: (state, { payload }) => {
      state.step = "styleSelection";
      state.first_name = "";
      state.last_name = "";
      state.id = null;
      state.generating = false;
      state.generatedImages = [];
      state.photo = "";
      state.photo_settings = {};
      state.form = {};
      state.qrUid = null;
      state.finalImage = null;
    },
    reroll: (state, { payload }) => {
      state.generating = true;
    },
    setGroupCurrent: (state, { payload }) => {
      state.groupCurrent = payload;
    },
    resetGroup: (state, { payload }) => {
      state.groupCurrent = 0;
      state.group = [...Array(state.groupMax)].map(() => ({
        id: null,
        image: null,
      }));
    },
    completeGroup: (state, { payload }) => {
      const photos = payload.group.filter(({ image }) => image);
      window._RDX["form-main"].ws.sendMessage("generateGroupPhoto", {
        clientName: payload.hash,
        photos,
      });

      state.groupCurrent = 0;
      state.group = [...Array(state.groupMax)].map(() => ({
        id: null,
        image: null,
      }));
      state.step = "styleSelection";
      state.first_name = "";
      state.last_name = "";
      state.id = null;
      state.generating = false;
      state.generatedImages = [];
      state.photo = "";
      state.photo_settings = {};
      state.form = {};
      state.qrUid = null;
    },
    // QR

    setQRUid: (state, { payload }) => {
      state.qrUid = payload;
    },
    setAutomaticPersona: (state, { payload }) => {
      state.automaticPersona = payload;
      state.workflowSettings.automatic = payload == true;

      state.step = "styleSelection";
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  setState,
  setPhotoForm,
  setConfirmPhotoForm,
  setPhoto,
  setContact,
  setSelectPhoto,
  setReset,
  reroll,
  setGroupCurrent,
  resetGroup,
  completeGroup,
  setQRUid,
  setAutomaticPersona,
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine;
