import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  clientName: "default",
  // automatic
  automaticPersona: null,
  hasAutomaticPersona: false,

  customFields: [],

  variables: {},
  theme: "Wallrus",
  step: "contactForm",
  lang: "fr-CA",
  first_name: "",
  last_name: "",
  id: null,
  generating: false,
  generatedImages: [],
  photo: "",
  photo_settings: {},
  form: {},

  // group settings
  groupMax: 1,
  groupCurrent: 0,
  group: [
    {
      id: null,
      image: null,
    },
  ],
  // SD API STATUS
  SDAPIStatus: {
    status: "",
    message: "",
  },

  qr: false,
  qrUid: null,
};

export const stateMachine = createSlice({
  name: "stateMachine",
  initialState,
  reducers: {
    onMessage: (state, { payload: { type, param } }) => {
      console.log(
        type,
        JSON.stringify({
          type: "stateMachine/onMessage",
          payload: { type, param },
        })
      );

      switch (type) {
        case "SDAPIStatus":
          state.SDAPIStatus = param;
          break;

        case "generatedImages":
          const { id, generatedImages } = param;

          if (state.id === id) {
            state.generatedImages = generatedImages;
            state.generating = false;
          }
          break;

        case "subimission":
          const groupIndex = state.group.findIndex(({ id }) => id === param.id);

          state.group[groupIndex] = {
            ...state.group[groupIndex],
            image: param.photo,
          };
          break;
        default:
      }
    },
    onDisconnection: (state, { payload }) => {
      state.connected = false;

      window._WSConnection["form-main"].io.connect();
    },
    onConnection: (state, { payload }) => {
      state.connected = true;
    },
    setState: (state, { payload }) => {
      state.step = payload;
    },
    setPhotoForm: (state, { payload }) => {
      state.photo_settings = payload;
    },
    setConfirmPhotoForm: (state, { payload }) => {
      state.photo_settings = payload;

      state.step = "personaPhoto";
    },
    setPhoto: (state, { payload }) => {
      state.id = payload.id;
      state.generating = true;
      state.step = "contactForm";
      // group enabled
      state.group[state.groupCurrent] = {
        id: payload.id,
      };
      window._RDX["form-main"].ws.sendMessage("updateExternalID", {
        id: payload.id,
        externalUID: state.qrUid,
      });
    },
    setContact: (state, { payload }) => {
      state.first_name = payload.first_name;
      state.last_name = payload.last_name;
      state.form = {
        ...payload
      };

      window._RDX["form-main"].ws.sendMessage("updateContact", {
        id: state.id,
        first_name: payload.first_name,
        last_name: payload.last_name,
        form: {
          ...payload
        },
      });

      state.step = "photoSelection";
    },
    setSelectPhoto: (state, { payload }) => {
      window._RDX["form-main"].ws.sendMessage("updatePhoto", {
        id: state.id,
        photo: payload,
      });

      state.step = "success";
    },
    setReset: (state, { payload }) => {
      state.step = state.qr ? "qrscanner" : "personaForm";
      state.first_name = "";
      state.last_name = "";
      state.id = null;
      state.generating = false;
      state.generatedImages = [];
      state.photo = "";
      state.photo_settings = {};
      state.form = {};
      state.qrUid = null;
    },
    reroll: (state, { payload }) => {
      state.generating = true;
    },
    setGroupCurrent: (state, { payload }) => {
      state.groupCurrent = payload;
    },
    resetGroup: (state, { payload }) => {
      state.groupCurrent = 0;
      state.group = [...Array(state.groupMax)].map(() => ({
        id: null,
        image: null,
      }));
    },
    completeGroup: (state, { payload }) => {
      const photos = payload.group.filter(({ image }) => image);
      window._RDX["form-main"].ws.sendMessage("generateGroupPhoto", {
        clientName: payload.hash,
        photos,
      });

      state.groupCurrent = 0;
      state.group = [...Array(state.groupMax)].map(() => ({
        id: null,
        image: null,
      }));
      state.step = state.qr ? "qrscanner" : "personaForm";
      state.first_name = "";
      state.last_name = "";
      state.id = null;
      state.generating = false;
      state.generatedImages = [];
      state.photo = "";
      state.photo_settings = {};
      state.form = {};
      state.qrUid = null;
    },
    // QR

    setQRUid: (state, { payload }) => {
      state.qrUid = payload;
      state.step = "personaForm";
    },
    setAutomaticPersona: (state, { payload }) => {
      state.automaticPersona = payload;

      let nextStep = state.qr ? "qrscanner" : "personaForm";

      state.step = payload == null ? "selectAutomatic" : nextStep;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  onMessage,
  onDisconnection,
  onConnection,
  setState,
  setPhotoForm,
  setConfirmPhotoForm,
  setPhoto,
  setContact,
  setSelectPhoto,
  setReset,
  reroll,
  setGroupCurrent,
  resetGroup,
  completeGroup,
  setQRUid,
  setAutomaticPersona,
} = stateMachine.actions;

export const actions = stateMachine.actions;

export default stateMachine;
