import React from 'react';
import ReactDOM from 'react-dom/client';
import NoSleep from 'nosleep.js';
import Layout from './components/Layout';

import './stylesheets/app.scss';

//Screen sleep prevention instance
window._NO_SLEEP = new NoSleep();

window.addEventListener('hashchange', function() {
  window.location.reload()
}, false);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>
);

