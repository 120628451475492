// import { useState, useEffect } from "react";
// import I18n from "rtcf-client/src/components/ui/I18n";
import Carousel from "../../../ui/Carousel";
import { setIndex } from "../stateMachine";

const Home = () => {
  return (
    <div className="ScreenCarousel__content">
      <Carousel setIndex={setIndex} />
    </div>
  );
};

export default Home;
