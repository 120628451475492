import { useEffect, useRef, useState } from "react";
import QRCodeStyling from "qr-code-styling";
import { useDispatch, useSelector } from "react-redux";
import { setGroupCurrent, setReset } from "../stateMachine";
import Spinner from "../../../ui/Spinner";
import I18n from "../../../ui/I18n";

import "./Success.scss";

import thumbsUP from "../../../../assets/thumbs-up.png";

const QRCODE_STYLE = {
  width: 200,
  height: 200,
  type: "svg",
  data: "null",
  margin: 0,
  qrOptions: {
    typeNumber: 0,
    mode: "Byte",
    errorCorrectionLevel: "Q",
  },
  dotsOptions: {
    color: "#005093",
  },
  cornersSquareOptions: {
    color: "#000000",
  },
};

const Success = () => {
  const dispatch = useDispatch();
  const groupCurrent = useSelector(
    ({ stateMachine }) => stateMachine.groupCurrent
  );
  const skipContact = useSelector(
    ({ stateMachine }) => stateMachine.workflowSettings.skipContact
  );
  const finalImage = useSelector(({ stateMachine }) => stateMachine.finalImage);
  const groupMax =
    useSelector(({ stateMachine }) => stateMachine.groupMax) || 0;
  const imageQR = useRef(null);
  const [hasQR, setHasQR] = useState(false);

  const handleReset = () => {
    dispatch(setReset());
  };
  const handleNextGroup = () => {
    dispatch(setGroupCurrent(groupCurrent + 1));
    dispatch(setReset());
  };

  const renderImage = () => {
    if (skipContact) {
      return (
        <div className="Success__QR-wrapper">
          <div className="Success__QR" ref={imageQR}>
          </div>
          {!hasQR ? (
            <Spinner className="Success__QR-spinner" />
          ): null}
          <svg viewBox="0 0 10 10" width="100" height="100" className="Success__QR-loading">
            <rect x="0" y="0" width="100" height="100" fill="white" />
          </svg>
        </div>
      );
    } else {
      return <img src={thumbsUP} />;
    }
  };

  useEffect(() => {
    if (finalImage && !hasQR) {
      if (imageQR.current) {
        const qrCode = new QRCodeStyling({
          ...QRCODE_STYLE,
          data: finalImage,
        });
        qrCode.append(imageQR.current);
        const refSvg = imageQR.current.querySelector("svg");
        if (refSvg) {
          refSvg.setAttribute("viewBox", "0 0 200 200");
          refSvg.setAttribute("shape-rendering", "crispEdges");
        }

        setHasQR(true);
      }
    }
  }, [finalImage, hasQR]);

  return (
    <div className="Success">
      {renderImage()}

      {skipContact ? (
        <p className="Success__scan-qr">
          <I18n t="success.scanQR" />
        </p>
      ): (
        <I18n t="success.success" />
      )}

      <button
        onClick={handleReset}
        className="Button Button--primary Button--big Button--inline"
      >
        <I18n t="success.buttons.restart" />
      </button>
      {groupMax > 1 && (
        <button
          onClick={handleNextGroup}
          className="Button Button--primary Button--big Button--inline"
        >
          <I18n t="success.buttons.next" />
        </button>
      )}
    </div>
  );
};

export default Success;
