import { useDispatch, useSelector } from "react-redux";
import logo from '../../../assets/logo.png'
import bellLogo from '../../../assets/bell-logo.png'
import { setAutomaticPersona } from "./stateMachine";

const Header = ()=>{
  const dispatch = useDispatch();
  const step = useSelector(({ stateMachine }) => stateMachine.step);
  const qrUid = useSelector(({ stateMachine }) => stateMachine.qrUid);
  const theme = useSelector(({ stateMachine }) => stateMachine?.theme);

  if(['personaPhoto', 'qrscanner', 'styleSelection'].includes(step)){
    return null;
  }

  const themeLogo = theme ==="Bell" ? bellLogo : logo;

  const handleResetAutomatic = () => {
    dispatch(setAutomaticPersona(null))
  }

  return(
    <header className="Header">
      <img src={themeLogo} onDoubleClick={handleResetAutomatic} className="Header-logo"/>
      {/* <div className="Header-uid">{qrUid && `UID: ${qrUid}`}</div> */}
    </header>
  )
}

export default Header;
